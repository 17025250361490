.dashboardlangugage-box {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 20px;
    margin: 10px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.banner-wrapper{
    display:flex ;
    height:80vh;
    flex-direction: row-reverse;
}

.cancel-icon{
    color:white; 
    cursor: pointer;
    font-size: 18px;
    font-weight: 800;
}

.hide-dashboard-icon{
    display: none !important;
}