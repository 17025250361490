@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

body {
  font-family: "Poppins";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom css */

/* Profile*/
.profile-form div {
  margin: 1% 0;
  text-align: left;
  /* flex-direction: column;
  display: flex; */
}
.profile-form input,
.profile-form select
{
  padding: 10px 20px;
  width: 100%;
  border-radius: 7px;
}

.profile-form .css-b62m3t-container {
  width: 100%;
  border-radius: 7px;
}
@media screen and (max-width: 440px) {
  .profile-form input,
  .profile-form select {
    padding: 10px 20px;
    width: 100%;
    border-radius: 7px;
  }
  .css-b62m3t-container {
    width: 100%!important;
  }
}


/* Custom Tailwind CSS */

.gradient-96 {
  background: linear-gradient(96deg, #2C3EA2, #192867);
}
.gradient-114 {
  background: linear-gradient(114deg, #2C3EA2, #192867);
}
