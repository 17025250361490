.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 2rem !important;
  height: 2rem;
  margin: 0 0.5rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/*  Add breakpoint for iPhone */
@media screen and (max-width: 480px) {
  .otpInput {
    /* width: 1.5rem !important;
    height: 1.5rem !important; */
    font-size: 1.5rem !important;
    padding: 8px !important;
    margin: 0px 4px !important;
  }
}
