.pd-profile {
    background-image: url(../../../../assets/images/personal-details-bg.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.studentDetails-Table tr td {
    font-size: 14px;
    padding: 5px 0px;

}
.studentDetails-Table tr td:nth-child(1) {
    text-align: left;
    color: #8F95B4;
    font-weight: 300;

}
.studentDetails-Table tr td:nth-child(2) {
    text-align: right;
    color: #101942DE;
    font-weight: 500;
}

@media screen and (max-width:640px) {
    .studentDetails-Table tr td {
        font-size: 12px;
    }
}