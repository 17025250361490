.wrapper{
    background-color:white; 
    padding:20px 20px 0px 20px;
    border-radius:16px;
    width: 30%;
}

.welcome-text{
font-size:22px; 
font-weight:600;
line-height:24px;
margin: 12px 0;
}

.welcome-details{
    font-size:16px; 
    font-weight:400;
    line-height:20px;
}

.button-container{
    display:flex ;
    justify-content:center;
    margin:22px 0 16px 0;
    flex-wrap: wrap;
    gap: 30px;
}

.cancel-btn{
    border-radius:12px;
    background-color:white;
    color:#3c57ad;
    padding:14px 34px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.proceed-btn{
border-radius:12px;
background-color:#101942;
color:white;
padding:14px 34px;
}

@media (min-width:320px) and (max-width:768px) {
    .wrapper {
        width: 90%;
        padding: 20px;
        top: 0;
        left: 0;
    }

    .button-container {
     justify-content: center;
     gap: 18px;
    }

    .cancel-btn{
        padding:14px 44px;
    }
    
    .proceed-btn{
    padding:14px 44px;
    }

    .welcome-text{
        font-size:18px; 
        font-weight:500;
        line-height:20px;
        margin-bottom:20px;
    }

    .welcome-details{
        font-size:14px; 
        font-weight:400;
        line-height:14px;
    }

    
    .button-container{
    margin:26px 0px 22px 0;
    }
}

.mobile-number{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}