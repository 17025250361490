/* .profile-check:checked + .profile-type-img {
  border: 1px solid #000;
  border-radius: 100%;
} */
.profile-type-img {
  /* padding: 15px; */
  /* border: 5px solid #d9d9d959; */
  border-radius: 100%;
  cursor: pointer;
  width: fit-content;

}
.profile-check:checked + .profile-type-img {
  font-weight: bold;
  /* border: 5px solid #bdbaba; */
  background-color: #101942;
  transition: 0.5s ease-in;
  /* border-radius: 100%; */
}


.profile-type-img img{
  width: 120px;
  /* height: 120px; */
}
.profile-check {
  position: absolute;
  width: 0;
}


@media screen and (max-width:440px) {
  .profile-type-img {
    width: 80px;
    height: 80px;
  }
  .profile-type-img img{
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width:768px) {
  .profile-type-img {
    width: 80px;
    height: 80px;
  }
  .profile-type-img img{
    width: 120px;
    object-fit: contain;
  }
}
