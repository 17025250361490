.sblock {
  display: block;
  animation: swipe-in 0.5s;
}
.shidden {
  display: none;
  animation: swipe-out 1s;
}

@keyframes swipe-in {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes swipe-out {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-200px);
  }
}

.div-ul {
  max-height: 285px;
  overflow: hidden;
  overflow-y: scroll;
}

@media (min-width: 400px) {
  .div-ul {
    max-height: unset;
    overflow-y: unset;
  }
}
